<template>
  <div :class="className">
    <div class="global-header" v-if="viewHeader">
      <slot name="header">
        <h3>
          {{ inspection.code }}
        </h3>
        <div class="buttons" v-if="showButtons">
          <button
            class="delete"
            v-if="canCancelInspection"
            @click="showInspectionCancelModel = true"
          >
            <i class="far fa-trash-alt"></i>
            <span> {{ $t("COMMON.CANCEL") }} </span>
          </button>
          <button class="edit" v-if="canEditInspection" @click="editInspection">
            <i class="far fa-edit"></i>
            <span> {{ $t("COMMON.UPDATE") }} </span>
          </button>
        </div>
      </slot>
    </div>
    <div class="global-body">
      <dl class="row width_2" v-if="showCode">
        <dt>{{ $t("COMMON.CODE") }}</dt>
        <dd>
          <!-- <object-link :object="inspection" /> -->
          {{ $objectDenomination(inspection) }}
        </dd>
      </dl>
      <dl class="row width_2 status">
        <dt>{{ $t("COMMON.STATUS") }}</dt>
        <dd>
          <inspection-status-badge :inspection="inspection" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="inspection.status == INSPECTION_STATUS_COMPLETED"
      >
        <dt>{{ $t("INSPECTIONS.SCORE") }}</dt>
        <dd>{{ inspection.score }}%</dd>
      </dl>

      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(inspection.customer?.id) &&
          !!inspection.customer?.customer_type
        "
      >
        <dt>{{ $t("COMMON.CUSTOMER") }}</dt>
        <dd>
          <users :users="[inspection.customer]" />
        </dd>
      </dl>

      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_REQUESTS) &&
          !!inspection.request
        "
      >
        <dt>{{ $t("COMMON.REQUEST") }}</dt>
        <dd>
          <object-link :object="inspection.request" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          $idExist(inspection.approver?.id) &&
          !!inspection.approver?.email
        "
      >
        <dt>{{ $t("COMMON.APPROVER") }}</dt>
        <dd>
          <object-link :object="inspection.approver" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!inspection.vehicle
        "
      >
        <dt>{{ $t("COMMON.VEHICLE") }}</dt>
        <dd>
          <object-link :object="inspection.vehicle" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!inspection.vehicle
        "
      >
        <dt>{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd>
          {{ inspection.odometer ?? inspection.vehicle?.odometer }}
          {{ inspection.vehicle?.odometer_unit }}
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_INSPECTION_FORMS) &&
          !!inspection.inspectionForm
        "
      >
        <dt>{{ $t("COMMON.INSPECTION_FORM") }}</dt>
        <dd>
          <object-link :object="inspection.inspectionForm" />
        </dd>
      </dl>

      <dl class="row width_2" v-if="!!inspection.created_at">
        <dt>{{ $t("INSPECTIONS.CREATED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.creator
        "
      >
        <dt>{{ $t("INSPECTIONS.CREATOR") }}</dt>
        <dd>
          <object-link :object="inspection.creator" />
        </dd>
      </dl>

      <dl class="row width_2" v-if="!!inspection.approved_at">
        <dt>{{ $t("INSPECTIONS.APPROVED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.approved_at, "LLLL") }}
        </dd>
      </dl>

      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.approvedBy
        "
      >
        <dt>{{ $t("INSPECTIONS.APPROVED_BY") }}</dt>
        <dd>
          <object-link :object="inspection.approvedBy" />
        </dd>
      </dl>
      <dl class="row width_2" v-if="!!inspection.assigned_at">
        <dt>{{ $t("INSPECTIONS.ASSIGNED_AT") }}</dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.assigned_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.assignedBy
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_BY") }}</dt>
        <dd>
          <object-link :object="inspection.assignedBy" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.assignedToUsers?.length
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_TO") }}</dt>
        <dd>
          <span v-for="(user, idx) in inspection.assignedToUsers" :key="idx">
            <object-link :object="user" />
            {{ inspection.assignedToUsers.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ROLES) &&
          !!inspection.assignedToRoles?.length
        "
      >
        <dt>{{ $t("INSPECTIONS.ASSIGNED_TO_GROUP") }}</dt>
        <dd>
          <span v-for="(role, idx) in inspection.assignedToRoles" :key="idx">
            <object-link :object="role" />
            {{ inspection.assignedToRoles.length - 1 > idx ? "," : " " }}<br />
          </span>
        </dd>
      </dl>
      <dl class="row width_2" v-if="!!inspection.started_at">
        <dt>
          {{ $t("INSPECTIONS.STARTED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.started_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.startedBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.STARTED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.startedBy" />
        </dd>
      </dl>
      <dl class="row width_2" v-if="!!inspection.completed_at">
        <dt>
          {{ $t("INSPECTIONS.COMPLETED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.completed_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.completedBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.COMPLETED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.completedBy" />
        </dd>
      </dl>
      <dl class="row width_2" v-if="inspection.canceled_at">
        <dt>
          {{ $t("INSPECTIONS.CANCELED_AT") }}
        </dt>
        <dd>
          {{ $timeZoneDateFormat(inspection.canceled_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          !!inspection.canceledBy
        "
      >
        <dt>
          {{ $t("INSPECTIONS.CANCELED_BY") }}
        </dt>
        <dd>
          <object-link :object="inspection.canceledBy" />
        </dd>
      </dl>
      <dl class="row width_2">
        <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd>
          <locations :locations="inspection.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="row width_2"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
          !!inspection.organization
        "
      >
        <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd>
          <organization :organization="inspection.organization" />
        </dd>
      </dl>

      <dl class="row width_2" v-if="false">
        <dt>
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd>
          <tags :tags="inspection.tags" @tagsUpdated="inspectionUpdated" />
        </dd>
      </dl>

      <dl class="row width_2">
        <dt>{{ $t("INSPECTIONS.EXCERPT") }}</dt>
        <dd v-html="inspection.excerpt"></dd>
      </dl>
    </div>
    <inspection-confirmation-modal
      :confirm-action="cancelInspection"
      :confirm-button-text="$t('COMMON.YES_CANCEL')"
      :loading="loading"
      :message="$t('INSPECTIONS.CANCEL_THIS_INSPECTION')"
      :open.sync="showInspectionCancelModel"
      modal-classes="modal-secondary cancel"
    />
  </div>
</template>

<script>
import {
  INSPECTION_STATUS_ASSIGNED,
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_PENDING,
  INSPECTION_STATUS_CANCELED,
  INSPECTION_STATUS_COMPLETED,
} from "@/constants/inspections";
import Tags from "@/components/Tags.vue";
import Organization from "@/components/Organization.vue";
import InspectionStatusBadge from "./InspectionStatusBadge.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import InspectionConfirmationModal from "@/components/InspectionConfirmationModal.vue";
import Users from "@/components/Users.vue";

export default {
  name: "inspection-view-global",

  components: {
    Tags,
    Organization,
    InspectionStatusBadge,
    InspectionConfirmationModal,
    Users,
  },

  props: {
    inspection: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    viewHeader: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "elite-tabs-wrapper-content",
    },
    showCode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      QUERY_ACTIONS_VIEW,
      showInspectionCancelModel: false,
      INSPECTION_STATUS_COMPLETED,
    };
  },

  computed: {
    canCancelInspection() {
      return (
        !!this.inspection &&
        this.$currentUserCan(this.$permissions.PERM_CANCEL_INSPECTIONS) &&
        [
          INSPECTION_STATUS_DRAFT,
          INSPECTION_STATUS_PENDING,
          INSPECTION_STATUS_ASSIGNED,
        ].includes(this.inspection.status)
      );
    },
    canEditInspection() {
      return (
        !!this.inspection &&
        ![INSPECTION_STATUS_CANCELED, INSPECTION_STATUS_COMPLETED].includes(
          this.inspection.status
        ) &&
        (this.$currentUserCan(this.$permissions.PERM_EDIT_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_APPROVE_INSPECTIONS) ||
          this.$currentUserCan(this.$permissions.PERM_ASSIGN_INSPECTIONS))
      );
    },
  },

  created() {},

  methods: {
    inspectionUpdated() {
      this.$emit("inspectionUpdated", true);
    },
    cancelInspection() {
      this.$emit("onCancelInspection");
    },
    editInspection() {
      this.$emit("onEditInspection");
    },
  },

  mounted() {},

  watch: {
    inspection(inspection) {},
  },
};
</script>
