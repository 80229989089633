<template>
  <div class="elite-tabs-wrapper-content">
    <div class="global-header">
      <h3>{{ estimate.code }} - {{ $t("COMMON.INSPECTION") }}</h3>
      <div class="buttons">
        <button class="edit" @click="showInspection">
          <span> {{ $t("INSPECTIONS.SHOW_INSPECTION") }} </span>
        </button>
      </div>
    </div>
    <inspection-view-global
      class-name=""
      :inspection="estimate.inspection"
      :view-header="false"
      show-code
    />
  </div>
</template>

<script>
import InspectionViewGlobal from "../../InspectionManagement/partials/InspectionViewGlobal.vue";

export default {
  name: "estimate-view-global",

  components: { InspectionViewGlobal },

  props: ["estimate"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    showInspection() {
      this.$router.push(this.$objectViewRoute(this.estimate.inspection));
    },
  },

  mounted() {},

  watch: {},
};
</script>
